
import { defineComponent, reactive, ref, onUnmounted, nextTick, computed } from 'vue';
import { useRoute } from 'vue-router';
import { message, Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { NavbarProps } from '@/API/types';
import {
  parameterSave,
  parameterSave2,
  getParameterSetup,
  getParameterData,
  getParameterDefaultSetting
} from '@/API/claim/claimParameter';
import useEventbus from '@/hooks/claim/useEventbus';
import useProgramData from '@/hooks/claim/useProgramData';
import { claimprogramProgramId } from '@/API/claim/claimParameter';
import { getBuListNew } from '@/API/checking/claimDelayRuleSetup';
import { getDefaultSettingList } from '@/API/claim/claimDocumentSample';
import Navbar from '@/views/Program/components/NavigationBar.vue';
import SubmissionTable from '../ParameterSetUp/components/ParameterClaim/SubmissionTable.vue';
import ExtendInformation from '../ParameterSetUp/components/ParameterClaim/ExtendInformation.vue';
import SupportingDocuments from '../ParameterSetUp/components/ParameterClaim/SupportingDocuments.vue';
import DocSubmissionTable from '../ParameterSetUp/components/ParameterDocument/DocSubmissionTable.vue';
import DocExtendInformation from '../ParameterSetUp/components/ParameterDocument/DocExtendInformation.vue';
import DocSupportingDocuments from '../ParameterSetUp/components/ParameterDocument/DocSupportingDocuments.vue';
import FileNamingRule from '../ParameterSetUp/components/ParameterDocument/FileNamingRule.vue';
import { DocType } from '../ParameterSetUp/types';
import useNavBar from '@/hooks/Program/useNavBar';
import useBudgetCheck from '@/hooks/Program/useBudgetCheck';
export default defineComponent({
  components: {
    Navbar,
    SubmissionTable,
    ExtendInformation,
    SupportingDocuments,
    DocSubmissionTable,
    DocExtendInformation,
    DocSupportingDocuments,
    FileNamingRule
  },
  setup() {
    const store = useStore();
    const currentProgramStatus = computed(() => store.state.pgmData.programStatusKey);
    const hasCurrentProgramSaved = computed(() => currentProgramStatus.value === 'Saved');

    const route = useRoute();
    const { customEmit } = useEventbus();
    const radioList = ref<any[]>([]);
    const itemOfferType = ref<any>({});
    //* programId ,offerType数据获取
    //! ---- 从design页面带回来的数据?
    const { offerTypeList } = useProgramData();
    const progDesignId = route.query.pgmId;
    // 数据回显所需的各模块数据变量定义
    //* claim Method模块数据
    const paraSetupClaimMethodVos = ref<[]>([]);
    //* claim parameter 的 extend information 数据
    const claimClaimParaSetupExtendInfoVos = ref<[]>([]);
    //* claim parameter 的 submission deadline 数据
    const claimParaSetupSubmissionDeadlineVos = ref<any[]>([]);
    //* claim parameter 的 supporting document 数据
    const claimParaSetupSupportingDocVos = ref<[]>([]);
    //* document collecntion parameter 的 extend information 数据
    const docParaSetupExtendInfoVos = ref<[]>([]);
    //* document collecntion parameter 的 file naming rule 数据
    const docParaSetupFileNamingRuleVos = ref<[]>([]);
    //* document collecntion parameter 的 submission deadline 数据
    const docParaSetupSubmissionDeadlineVos = ref<any[]>([]);
    //* document collecntion parameter 的 supporting document 数据
    const docParaSetupSupportingDocVos = ref<[]>([]);
    //* 项目code programCodel
    const programCod = ref<any>('');
    //! 是否保存？
    const isSaved = ref<any>(true);
    // 数据回显
    const bu = ref('');
    const getProgramBuName = async (currentBu: number) => {
      const buList = await getBuListNew();
      const buIdNameMap = buList.reduce((resultMap: any, item: any) => {
        return { ...resultMap, [item.id]: item };
      }, {});
      if (buIdNameMap[currentBu].parentId === null) {
        return buIdNameMap[currentBu].nameEn;
      } else {
        return buIdNameMap[buIdNameMap[currentBu].parentId].nameEn;
      }
    };
    //? claim method 初始化相关
    const getOfferTypeData = () => {
      return getParameterData(progDesignId).then(async (data: any) => {
        if (!data.incentiveCategory || data.incentiveCategory.length === 0) {
          message.error('请设置Offer Type');
          return;
        }
        const programBu = parseInt(data.programBu);
        bu.value = await getProgramBuName(programBu);
        radioList.value = [];
        data.incentiveCategory &&
          data.incentiveCategory.forEach((item: any) => {
            item.offerType &&
              item.offerType.forEach((i: any) => {
                const geti = {
                  progDesignOfferTypeName: i.nameEn,
                  progDesignOfferTypePathKey: i.pathKey,
                  progDesignOfferTypeId: i.id,
                  incentiveCategory: item.nameEn,
                  defaultDisabled: false
                };
                radioList.value.push(geti);
              });
          });
        store.commit('claim/updateClearClaimMethodData');
        store.commit('claim/updateClaimMethodData', radioList.value);
      });
    };

    //? 获取默认值
    const defaultDateRange = ref(0);
    const isSubmissionDeadlineRequired = ref(false);
    const offerTypeDefaultSettings = ref({});

    const getDefaultSetting = (params: any) => {
      return getParameterDefaultSetting(params).then(async (res: any) => {
        res.offerTypeDefaultSettings.forEach((item: any) => {
          const { offerType, ...rest } = item;
          offerTypeDefaultSettings.value[offerType] = rest;
        });
        isSubmissionDeadlineRequired.value = res.submissionDeadlineRequired;
        defaultDateRange.value = parseInt(res.defaultSubmissionDeadline === '' ? 0 : res.defaultSubmissionDeadline);
      });
    };

    let firstTabClick = true;
    const clickTab = (activeKey: string) => {
      if (activeKey === '2' && firstTabClick) {
        customEmit('showDocParaSetupSubmissionData', docParaSetupSubmissionDeadlineVos.value);
        firstTabClick = false;
      }
    };

    const periodSelectList = ref<any[]>([]);
    const periodMap = reactive<any>({});

    //? submission deadline 初始化相关
    const initSubmissionDeadline = (tableCellSelect: any, period: any) => {
      periodSelectList.value.length = 0;
      if (!isSubmissionDeadlineRequired.value) {
        if (periodSelectList.value.length === 0) {
          periodSelectList.value = tableCellSelect.slice(0, 2);
        }
        return [];
      }
      const ALLOWED_PERIOD: any[] = [];
      //*  仅take retail date 和 wholesale date其中一个字段，若两个都维护，仅展示retail date字段
      const attrList = period.map((item: any) => {
        return item['ATTR'].val;
      });
      if (attrList.includes('Sale.retailDate')) {
        ALLOWED_PERIOD.push('Sale.retailDate');
      } else {
        ALLOWED_PERIOD.push('Sale.wholesaleDate');
      }
      tableCellSelect.forEach((item: any) => {
        if (ALLOWED_PERIOD.includes(item.id)) {
          periodMap[item.id] = item.display;
          periodSelectList.value.push(item);
        }
      });
      if (periodSelectList.value.length === 0) {
        periodSelectList.value = tableCellSelect.slice(1);
      }
      return ALLOWED_PERIOD;
    };

    //? Supporting Documents 初始化相关
    const docSettings = ref<any>([]);
    const claimSettings = ref<any>([]);
    const getSupportingDocumentsInitListParams = (res: any) => {
      const offerTypeCategoryValues = Object.values(res['offer']).map((type: any) => {
        return type.offerTypeName;
      });
      const customerTypeCategoryValues = res['customer']
        .reduce((allCells: any, type: any) => {
          return [...allCells, ...type.tableCell];
        }, [])
        .map((cell: any) => {
          return cell['Customer.type'].val;
        });
      const specialMarksCategoryValues = [];
      if (res['orderProtection']) specialMarksCategoryValues.push('Order Protection');
      if (res['presale']) specialMarksCategoryValues.push('Pre Sale');
      return [
        {
          bu: bu.value,
          category: 'Offer Type',
          categoryValues: offerTypeCategoryValues,
          tab: 'Claim'
        },
        {
          bu: bu.value,
          category: 'Customer Type',
          categoryValues: customerTypeCategoryValues,
          tab: 'Claim'
        },
        {
          bu: bu.value,
          category: 'Special Mark',
          categoryValues: specialMarksCategoryValues,
          tab: 'Doc'
        }
      ];
    };

    const initParameterTemplate = async (isReset = false) => {
      if (!progDesignId) return;
      const res = await claimprogramProgramId({ params: { programId: progDesignId } });
      itemOfferType.value = res['offer'] as any;
      programCod.value = res.programCode;
      //? Supporting Documents 初始化相关
      await getOfferTypeData();
      const params = getSupportingDocumentsInitListParams(res);
      await getDefaultSetting(params);
      if (radioList.value.length > 0 && Array.isArray(radioList.value)) {
        radioList.value.forEach((item: any) => {
          const { defaultClaimMethod, claimMethodEditable } =
            offerTypeDefaultSettings.value[item.progDesignOfferTypeName];
          item.defaultDisabled = !claimMethodEditable;
          item.claimMethod = defaultClaimMethod;
        });
      }
      getDefaultSettingList(params).then((res: any) => {
        console.log('Supporting Documents 初始化相关');
        claimSettings.value = res.claimSettings;
        docSettings.value = res.docSettings;
      });
      //? submisson deadline 初始化相关
      const tableCellSelect = res.eligibility.period.tableCellSelect;
      const period = res.eligibility.period.tableCell;
      const ALLOWED_PERIOD = initSubmissionDeadline(tableCellSelect, period);
      const periodList = [];
      for (let i = 0; i < period.length; i++) {
        const val = period[i]['ATTR']['val'];
        if (ALLOWED_PERIOD.includes(val)) {
          periodList.push({
            programCode: res.programCode,
            progDesignId: progDesignId,
            eligiblePeriodId: val,
            eligiblePeriodName: periodMap[val],
            eligiblePeriodFrom: period[i]['FROM'].val,
            eligiblePeriodTo: period[i]['TO'].val,
            submissionDateFrom: '',
            submissionDateTo: ''
          });
        }
      }
      claimParaSetupSubmissionDeadlineVos.value = periodList;
      docParaSetupSubmissionDeadlineVos.value = periodList;
      customEmit('showClaimParaSetupSubmissionData', claimParaSetupSubmissionDeadlineVos.value);
      getTabStatus();
      if (isReset) message.success('Reset successfully!');
    };

    //? 当从 parameter tab 页进入的时候 获取submission deadline 下拉项
    const getDeadlinDrops = () => {
      if (progDesignId) {
        claimprogramProgramId({
          params: { programId: progDesignId }
        }).then(async (res: any) => {
          //? 获取bu
          const programBu = parseInt(res.businessUnit.selected);
          bu.value = await getProgramBuName(programBu);
          itemOfferType.value = res['offer'] as any;
          programCod.value = res.programCode;
          //? submisson deadline 初始化相关
          const tableCellSelect = res.eligibility.period.tableCellSelect;
          const period = res.eligibility.period.tableCell;
          initSubmissionDeadline(tableCellSelect, period);
          const params = getSupportingDocumentsInitListParams(res);
          await getDefaultSetting(params);
        });
      }
    };

    //* 从claim后台获取数据
    const getDataFromClaim = () => {
      if (progDesignId) {
        console.log('2. 判断是否存在 parameter');
        getParameterSetup({
          progDesignId: progDesignId as string
        }).then((res) => {
          if (res.paraSetupClaimMethodVos === null) {
            // message.warning('该项目尚未生成Parameter！');
            console.log('3. 没有 parameter，则初始化，获取默认数据');
            initParameterTemplate();
            return;
          }
          console.log('4. 若有 parameter，则获取后台数据');
          getDeadlinDrops();
          paraSetupClaimMethodVos.value = res.paraSetupClaimMethodVos;
          //? 清除vuex 中的 claimMehtodData
          store.commit('claim/updateClearClaimMethodData');
          store.commit('claim/updateClaimMethodData', paraSetupClaimMethodVos.value);
          // 回显claimMethod
          radioList.value = paraSetupClaimMethodVos.value;
          if (radioList.value && radioList.value.length > 0 && Array.isArray(radioList.value)) {
            radioList.value.forEach((item: any) => {
              if (
                item.progDesignOfferTypePathKey === 'fa' ||
                item.progDesignOfferTypePathKey === 'fa_buy_down' ||
                item.progDesignOfferTypePathKey === 'retention'
              ) {
                item.defaultDisabled = true;
              }
            });
          }
          claimClaimParaSetupExtendInfoVos.value = res.claimClaimParaSetupExtendInfoVos;
          claimParaSetupSubmissionDeadlineVos.value = res.claimParaSetupSubmissionDeadlineVos;
          claimParaSetupSupportingDocVos.value = res.claimParaSetupSupportingDocVos;
          docParaSetupExtendInfoVos.value = res.docParaSetupExtendInfoVos;
          docParaSetupSupportingDocVos.value = res.docParaSetupSupportingDocVos;
          docParaSetupFileNamingRuleVos.value = res.docParaSetupFileNamingRuleVos;
          docParaSetupSubmissionDeadlineVos.value = res.docParaSetupSubmissionDeadlineVos;

          customEmit('showClaimParaSetupExtendData', claimClaimParaSetupExtendInfoVos.value);
          customEmit('showClaimParaSetupSubmissionData', claimParaSetupSubmissionDeadlineVos.value);
          customEmit('showClaimParaSetupSupportingData', claimParaSetupSupportingDocVos.value);
          customEmit('showDocParaSetupExtendData', docParaSetupExtendInfoVos.value);
          customEmit('showDocParaSetupSupportingData', {
            supportingDocument: docParaSetupSupportingDocVos.value,
            fileNamingRule: docParaSetupFileNamingRuleVos.value
          });
          customEmit('showDocParaSetupSubmissionData', docParaSetupSubmissionDeadlineVos.value);

          getTabStatus();
        });
      }
    };

    const paramType = route.query.type;
    //? 初始化页面数据
    const initParamData = () => {
      console.log('1. 进入页面');
      getDataFromClaim();
    };
    initParamData();
    // 导航的数据
    const { navArr, getTabStatus, programAllStatus } = useNavBar();
    // * 跳转到 budget 页面
    useBudgetCheck(programAllStatus);

    const updateClaimMethod = () => {
      const option = reactive<any>({
        name: 'paraSetupClaimMethodVos',
        rawData: []
      });
      if (radioList.value !== null && radioList.value.length > 0) {
        for (let i = 0; i < radioList.value.length; i++) {
          option.rawData.push({
            progDesignOfferTypeId: radioList.value[i].progDesignOfferTypeId,
            progDesignOfferTypePathKey: radioList.value[i].progDesignOfferTypePathKey,
            progDesignOfferTypeName: radioList.value[i].progDesignOfferTypeName,
            claimMethod: radioList.value[i].claimMethod,
            incentiveCategory: radioList.value[i].incentiveCategory,
            progDesignId: progDesignId
          });
        }
      }
      store.commit('claim/updateSaveParamsData', option);
    };

    const handleReset = () => {
      Modal.confirm({
        title: 'Tips',
        content: 'Are you sure you want to reset program parameter to default value?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          initParameterTemplate(true);
          customEmit('showClaimParaSetupExtendData', []);
          customEmit('showDocParaSetupExtendData', []);
          customEmit('showDocParaSetupSupportingData', {
            supportingDocument: [],
            fileNamingRule: []
          });
          customEmit('showDocParaSetupSubmissionData', []);
        }
      });
    };

    const saveApi = () => {
      const params = store.state.claim.saveParamsData;
      if (
        isSubmissionDeadlineRequired.value &&
        (!params.claimParaSetupSubmissionDeadlineVos || params.claimParaSetupSubmissionDeadlineVos.length === 0)
      ) {
        message.error('Please input submission deadline');
        return;
      }
      parameterSave(params).then(
        (res) => {
          if (res.code === '0') {
            message.success('Save Successfully');
          }
        },
        (error) => {
          console.log(error);
        }
      );
      parameterSave2(params).then(
        (res) => {
          nextTick(() => {
            setTimeout(() => {
              getDataFromClaim();
            }, 1500);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    };

    const handleSave = () => {
      // 把progDesignId放到save里
      const optionDesignId = reactive<any>({
        name: 'progDesignId',
        rawData: progDesignId
      });
      store.commit('claim/updateSaveParamsData', optionDesignId);
      // 把programcode放到save里
      const optionProgramCode = reactive<any>({
        name: 'programCode',
        rawData: programCod.value
      });
      store.commit('claim/updateSaveParamsData', optionProgramCode);
      updateClaimMethod();
      // claim Params保存存值到vuex
      customEmit('saveParaSetupSubmissionDeadline');
      customEmit('saveParamsExtend');
      customEmit('saveDocParams');
      // document 保存存值到vuex
      customEmit('saveDocSubmissionParams');
      customEmit('saveDocSupportingDocVosParams');
      customEmit('saveDocExtendInfoVosParams');
      customEmit('saveDocFileNamingRuleVosParams');

      // 校验是否选择File Naming Rule
      const unselectedFileRule = store.state.claim.saveParamsData.docParaSetupFileNamingRuleVos.some(
        (item: any) => !item.rule
      );
      if (unselectedFileRule) {
        message.warn('Please select file naming rule before save.');
        return;
      }

      // 触发保存事件
      saveApi();
    };
    onUnmounted(() => {
      store.commit('claim/updateClearClaimMethodData');
      store.commit('claim/updateClearParamsData');
      store.commit('claim/updateClearDocDocumentsData');
      store.commit('claim/updateClearExtendInfoData');
    });
    return {
      bu,
      clickTab,
      itemOfferType,
      programCod,
      periodMap,
      defaultDateRange,
      periodSelectList,
      navArr,
      activeKey: ref('1'),
      progDesignId,
      radioList,
      offerTypeList,
      handleSave,
      paraSetupClaimMethodVos,
      claimClaimParaSetupExtendInfoVos,
      claimParaSetupSubmissionDeadlineVos,
      claimParaSetupSupportingDocVos,
      docParaSetupExtendInfoVos,
      docParaSetupFileNamingRuleVos,
      docParaSetupSubmissionDeadlineVos,
      docParaSetupSupportingDocVos,
      paramType,
      claimSettings,
      docSettings,
      hasCurrentProgramSaved,
      handleReset,
      isSubmissionDeadlineRequired
    };
  }
});
