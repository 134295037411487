
import { defineComponent, reactive, Ref, ref, onBeforeMount, onMounted, watch } from 'vue';
import useProgramData from '@/hooks/claim/useProgramData';
import { getExtendInfo, getExtendInfoList } from '@/API/claim/claimParameter';
import { findAll, findAllByBu } from '@/API/claim/claimDocumentSample';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
import { isArrayLike } from 'lodash';
interface DataItem {
  key: string;
  extend: string;
  extendValue: string;
  docNameCn: string;
  docNameEn: string;
  fileType: string;
  isRequired: string;
}

export default defineComponent({
  props: {
    itemOfferType: {
      type: Object,
      default: () => ({}),
      required: true
    },
    claimSettings: {
      type: Array,
      default: () => [],
      required: true
    },
    bu: {
      type: String,
      default: '',
      required: true
    },
    paramType: {
      type: String,
      default: '',
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { customOn } = useEventbus();
    const claimMethodData = store.state.claim.claimMethodData;
    const { progDesignId, offerTypeList } = useProgramData();

    // 清空原有数据显示
    const clearData = () => {
      for (let i = 0; i < offerTypeList.value.length; i++) {
        offerTypeList.value[i].forms = [];
      }
    };

    onBeforeMount(() => {
      clearData();
    });
    // 回显数据获取
    const showData = (params: any) => {
      clearData();
      if (
        Array.isArray(store.state.claim.claimMethodData) &&
        store.state.claim.claimMethodData.length > 0 &&
        Array.isArray(params)
      ) {
        for (let j = 0; j < params.length; j++) {
          for (let k = 0; k < offerTypeList.value.length; k++) {
            if (params[j]['progDesignOfferTypePathKey'] === offerTypeList.value[k]['progDesignOfferTypeId']) {
              const obj = {};
              obj['key'] = `${j}`;
              obj['abbreviation'] = params[j]['abbreviation'];
              obj['docDicManagementId'] = params[j]['docDicManagementId'];
              obj['docNameCn'] = params[j]['docDicManagementNameCn'];
              obj['docNameEn'] = params[j]['docDicManagementNameEn'];
              obj['docType'] = params[j]['docDicManagementType'];
              obj['extend'] = params[j]['extendNameCn'];
              obj['extendValue'] = params[j]['extendValue'];
              obj['filePath'] = params[j]['filePath'];
              obj['isRequired'] = params[j]['isRequired'];
              obj['isVinbased'] = params[j]['isVinbased'];
              obj['paraExtendInfoId'] = params[j]['paraExtendInfoId'];
              obj['id'] = params[j]['id'];
              offerTypeList.value[k].forms.push(obj);
            }
          }
        }
      }
    };
    const extInfDataArr = ref<any[]>([]);
    const columns = [
      {
        title: 'Extend',
        dataIndex: 'extend',
        width: '200px',
        slots: { customRender: 'extend' }
      },
      {
        title: 'Extend Value',
        dataIndex: 'extendValue',
        width: '140px',
        slots: { customRender: 'extendValue' }
      },
      {
        title: 'Document Name(CN)',
        dataIndex: 'docNameCn',
        width: '200px',
        slots: { customRender: 'docNameCn' }
      },
      {
        title: 'Document Name(EN)',
        dataIndex: 'docNameEn',
        width: '140px',
        ellipsis: true,
        slots: { customRender: 'docNameEn' }
      },
      {
        title: 'File Type',
        dataIndex: 'fileType',
        width: '140px',
        slots: { customRender: 'fileType' }
      },
      {
        title: 'Is required',
        dataIndex: 'isRequired',
        width: '120px',
        slots: { customRender: 'isRequired' }
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        slots: { customRender: 'operation' }
      }
    ];
    const findAllData = ref<any[]>([]);
    const extendInfoData = ref<any[]>([]);
    const offerInf = ref<any>({});

    const extInfData = (params: any) => {
      extInfDataArr.value = params;
      const enumItems: any[] = [];
      extInfDataArr.value.map((item: any) => {
        if (item.fieldType === 'Enumeration') {
          // item.id = item.eligiblePeriodId;
          // item.id = item.paraExtendInfoId;//! 修改了上面的绑定 id=>paraExtendInfoId
          enumItems.push(item);
          // console.log(268,item)
        }
      });
      extendInfoData.value = enumItems;
    };

    const getDefaultSettings = () => {
      if (props.claimSettings.length === 0) return;
      for (let i = 0; i < offerTypeList.value.length; i++) {
        let key = 0;
        const typeName = offerTypeList.value[i].progDesignOfferTypeName;
        offerTypeList.value[i].forms = findAllData.value.reduce((docs: any, doc: any) => {
          const currentDoc = props.claimSettings[typeName].find((item: any) => {
            return item.docNameEn === doc.docNameEn;
          });
          if (currentDoc) {
            key = key + 1;
            const newDoc = { ...doc };
            // 新parameter创建的时候为空就可以
            newDoc.id = '';
            newDoc.isRequired = currentDoc.isRequired || 'N';
            newDoc.key = `${key}`;
            return [...docs, newDoc];
          }
          return [...docs];
        }, []);
      }
    };

    // find All接口调用
    const findByBu = (bu: string) => {
      findAllByBu({ bu })
        .then((res) => {
          for (let i = 0; i < res.length; i++) {
            res[i]['docDicManagementId'] = res[i]['id'];
            res[i]['isRequired'] = res[i]['isRequired'] ?? 'Y';
          }
          findAllData.value = res;
        })
        .then(() => {
          getDefaultSettings();
        });
    };
    watch(
      [() => props.bu, () => props.claimSettings],
      ([newBu, newParams]: any[]) => {
        // if (newBu !== '' && Object.keys(newParams).length > 0) {
        console.log('props.bu', newBu);
        if (newBu !== '') {
          findByBu(newBu);
        }
      },
      { immediate: true }
    );

    const onDocInfo = (newDocNameCn: string, item: any, index: number) => {
      const currentRow = item.forms[index];
      const currentDocNameCn = currentRow.docNameCn;
      const sameExtendOrExtendValueRows = item.forms.filter(
        (row: any, currentIndex: number) =>
          currentIndex !== index && row.extend === currentRow.extend && row.extendValue === currentRow.extendValue
      );
      if (sameExtendOrExtendValueRows.length > 0) {
        const theseRowsDocNameCn = sameExtendOrExtendValueRows.map((row: any) => row.docNameCn);
        if (theseRowsDocNameCn.includes(newDocNameCn)) {
          message.error('Duplicated document name.');
          window.setTimeout(() => {
            item.forms[index].docNameCn = null;
          }, 50);
          window.setTimeout(() => {
            item.forms[index].docNameCn = currentDocNameCn;
          }, 100);
          return;
        }
      }
      // 新创建的为''，已保存过，则要保证id不变
      const currentId = item.forms[index]['id'] || '';
      findAllData.value.forEach((data: any) => {
        if (data.docNameCn === newDocNameCn) {
          Object.keys(data).map((key) => (item.forms[index][key] = data[key]));
          item.forms[index]['id'] = currentId;
        }
      });
    };

    const onExtendInfo = (val: string, item: any, index: number) => {
      const params = { id: val };
      getExtendInfo({ params }).then((res: any) => {
        const { enumValue, fieldNameCn, fieldNameEn, fieldType, id, defaultValue } = res;
        item.forms[index]['extend'] = fieldNameCn;
        item.forms[index]['paraExtendInfoId'] = id;
        if (fieldType === 'Enumeration') {
          item.forms[index]['enumValue'] = enumValue.split(',');
        }
        item.forms[index]['extendValue'] = defaultValue;
      });
    };
    const getOfferType = (params: any) => {
      offerInf.value = params.offer;
    };
    const saveDocParams = () => {
      const queryParams = [];
      for (let j = 0; j < offerTypeList.value.length; j++) {
        const formsCur = offerTypeList.value[j].forms;
        console.log('formsCur', formsCur);
        for (let k = 0; k < formsCur.length; k++) {
          const obj = {
            id: formsCur[k]['id'],
            abbreviation: formsCur[k]['abbreviation'],
            docDicManagementId: formsCur[k]['docDicManagementId'],
            docDicManagementNameCn: formsCur[k]['docNameCn'],
            docDicManagementNameEn: formsCur[k]['docNameEn'],
            docDicManagementType: formsCur[k]['docType'],
            extendNameCn: formsCur[k]['extend'],
            extendValue: formsCur[k]['extendValue'],
            filePath: formsCur[k]['filePath'],
            isRequired: formsCur[k]['isRequired'],
            isVinbased: formsCur[k]['isVinbased'],
            paraExtendInfoId: formsCur[k]['paraExtendInfoId'],
            progDesignId: progDesignId.value,
            progDesignOfferTypeId: (props as any).itemOfferType[offerTypeList.value[j]['progDesignOfferTypeId']][
              'offerTypeId'
            ],
            progDesignOfferTypePathKey: (props as any).itemOfferType[offerTypeList.value[j]['progDesignOfferTypeId']][
              'offerTypePathKey'
            ],
            progDesignOfferTypeName: (props as any).itemOfferType[offerTypeList.value[j]['progDesignOfferTypeId']][
              'offerTypeName'
            ]
          };
          if (
            obj.docDicManagementNameCn !== '' ||
            obj.docDicManagementNameEn !== '' ||
            obj.extendNameCn !== '' ||
            obj.extendValue !== '' ||
            obj.isRequired !== ''
          ) {
            queryParams.push(obj);
          }
        }
      }
      const option = {
        name: 'claimParaSetupSupportingDocVos',
        rawData: queryParams
      };
      store.commit('claim/updateSaveParamsData', option);
    };

    onMounted(() => {
      customOn('showClaimParaSetupSupportingData', showData);
      customOn('saveDocParams', saveDocParams);
      customOn('ExtendInformationNowData', extInfData);
      customOn('designData', getOfferType);
    });
    const onDelete = (item: any, key: string, index: number) => {
      const arr = [];
      for (let i = 0; i < item.forms.length; i++) {
        if (item.forms[i]['key'] !== key) {
          arr.push(item.forms[i]);
        }
      }
      item.forms = arr;
    };
    const handleAdd = (item: any) => {
      const key = item.forms.length + 1;
      const newData = {
        key: `${key}`,
        extend: null,
        extendValue: null,
        docNameCn: '',
        docNameEn: '',
        fileType: '',
        isRequired: 'Y',
        id: ''
      };
      item.forms.push(newData);
    };
    return {
      getOfferType,
      extInfDataArr,
      extInfData,
      progDesignId,
      findAllData,
      extendInfoData,
      offerTypeList,
      columns,
      onDelete,
      onDocInfo,
      onExtendInfo,
      handleAdd,
      saveDocParams,
      showData,
      claimMethodData,
      clearData
    };
  }
});
