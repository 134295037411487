import { reactive, ref } from 'vue';
const useProgramData = () => {
    interface OfferType {
        progDesignOfferTypeId: string;
        progDesignOfferTypeName: string;
        claimMethod: string;
        defaultDisabled: boolean;
        incentiveCategory: string;
        forms: any[];
    }
    const progDesignId = ref<string>('');
    const offerTypeList = ref<OfferType[]>([]);
    const periodSelectList = ref<string[]>([]);
    const periodPgmList = ref<[]>([]);
    const userInfo = window.sessionStorage.getItem('vuex');
    const programData = JSON.parse(userInfo as string).pgmData;
    progDesignId.value = programData.programId;
    const selected = programData.businessUnit.selected;

    const option = programData.businessUnit.option;
    for (let i = 0; i < option.length; i++) {
        if (option[i]['id'] === selected) {
            const categoryList = option[i]['category'];
            for (let j = 0; j < categoryList.length; j++) {
                const offerList = categoryList[j]['offerType'];
                for (let z = 0; z < offerList.length; z++) {
                    if (offerList[z]['selected']) {
                        if (offerList[z]['id'] === 'fa') {
                            offerTypeList.value.push({
                                progDesignOfferTypeId: offerList[z]['id'],
                                progDesignOfferTypeName:
                                    offerList[z]['display'],
                                claimMethod: '4',
                                defaultDisabled: true,
                                incentiveCategory: categoryList[j]['groupName'],
                                forms: reactive([]),
                            });
                        } else {
                            offerTypeList.value.push({
                                progDesignOfferTypeId: offerList[z]['id'],
                                progDesignOfferTypeName:
                                    offerList[z]['display'],
                                claimMethod: '1',
                                defaultDisabled: false,
                                incentiveCategory: categoryList[j]['groupName'],
                                forms: reactive([]),
                            });
                        }
                    }
                }
            }
        }
    }
    periodSelectList.value = programData.eligibility.period.tableCellSelect;
    periodPgmList.value = programData.eligibility.period.tableCell;
    const period = programData.eligibility.period.tableCell;
    const periodList = ref<any[]>([]);
    for (let i = 0; i < period.length; i++) {
        if (period[i]['ATTR']['val'] === '1101') {
            const periodName = ref<string>('');
            periodSelectList.value.forEach((item: any) => {
                if (item.id === '1101') {
                    periodName.value = item.display;
                }
            });
            periodList.value.push({
                periodFromObj: period[i]['FROM'],
                periodIdObj: period[i]['ATTR'],
                periodName: periodName.value,
                periodToObj: period[i]['TO'],
                progDesignIdObj: progDesignId.value,
                id: period[i]['id'],
                key: period[i]['key'],
            });
        }
    }
    return {
        progDesignId,
        offerTypeList,
        periodPgmList,
        periodSelectList,
        periodList,
    };
};
export default useProgramData;
