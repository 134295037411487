
import { defineComponent, Ref, ref, computed, onMounted, nextTick } from 'vue';
import useProgramData from '@/hooks/claim/useProgramData';
import { getExtendInfoList } from '@/API/claim/claimParameter';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
interface DataItem {
    key: string;
    docNameCn: string;
    docNameEn: string;
    docType: string;
    // fileType: string;
    isRequired: string;
}

export default defineComponent({
    setup() {
        const store = useStore();
        const claimMethodData = store.state.claim.claimMethodData;
        // 获取supporting document数据
        const findAllData = computed(() => store.state.claim.docDocumentsData);
        const { customOn } = useEventbus();
        const columns = [
            {
                title: 'Document Name(CN)',
                dataIndex: 'docNameCn',
                width: '200px',
                slots: { customRender: 'docNameCn' },
            },
            {
                title: 'Document Name(EN)',
                dataIndex: 'docNameEn',
                width: '180px',
                ellipsis: true,
                slots: { customRender: 'docNameEn' },
            },
            {
                title: 'File Type',
                dataIndex: 'fileType',
                width: '140px',
                slots: { customRender: 'fileType' },
            },
            {
                title: 'Value of Extend',
                dataIndex: 'fieldNameEn',
                width: '200px',
                slots: { customRender: 'fieldName' },
            },
            {
                title: 'Rule',
                dataIndex: 'rule',
                width: '200px',
            },
        ];
        // const dataSource = ref<any[]>([]);
        const dataSource = computed(() => store.state.claim.docDocumentsData);
        const extendInfoData = ref([]);
        // 获取扩展信息 ExtendInfo列表
        getExtendInfoList().then((res: any) => {
            extendInfoData.value = res;
        });
        // programId ,offerType数据获取
        const { progDesignId } = useProgramData();
        const extendListData = computed(() => {
            return store.state.claim.docExtendData;
        });
        const onDocInfo = (val: string, item: any, index: number) => {
            findAllData.value.forEach((data: any) => {
                console.log(data);
                if (data.id === val) {
                    Object.keys(data).map((key) => {
                        dataSource.value[index][key] = data[key];
                    });
                }
            });
            if (dataSource.value[index]['fieldNameCn']) {
                dataSource.value[index][
                    'rule'
                ] = `${dataSource.value[index]['fieldNameCn']}_${dataSource.value[index]['abbreviation']}`;
            } else {
                dataSource.value[index]['rule'] = '';
            }
        };
        // 下拉选择Value of Extend时带出相关字段信息
        const onExtend = (value: string, record: any, index: number) => {
            for (let i = 0; i < extendListData.value.length; i++) {
                if (extendListData.value[i].id === value) {
                    dataSource.value[index]['fieldNameEn'] =
                        extendListData.value[i].fieldNameEn;
                    dataSource.value[index]['fieldNameCn'] =
                        extendListData.value[i].fieldNameCn;
                    if (dataSource.value[index]['abbreviation']) {
                        dataSource.value[index][
                            'rule'
                        ] = `${extendListData.value[i].fieldNameCn}_${dataSource.value[index]['abbreviation']}`;
                    } else {
                        dataSource.value[index]['rule'] = '';
                    }
                }
            }
        };
        // 保存此模块数据到vuex
        const saveDocFileNamingRuleVosParams = () => {
            const queryParams = ref<any[]>([]);
            for (let k = 0; k < dataSource.value.length; k++) {
                const currentName = dataSource.value[k]['docNameCn'];
                if (currentName !== '') {
                    const obj = {
                        abbreviation: dataSource.value[k]['abbreviation'],
                        docDicManagementId:
                            dataSource.value[k]['docDicManagementId'],
                        docDicManagementNameCn:
                            dataSource.value[k]['docNameCn'],
                        docDicManagementNameEn:
                            dataSource.value[k]['docNameEn'],
                        // docDicManagementType: dataSource.value[k]['fileType'],
                        docDicManagementType: dataSource.value[k]['docType'],
                        filePath: dataSource.value[k]['filePath'],
                        isRequired: dataSource.value[k]['isRequired'],
                        isVinbased: dataSource.value[k]['isVinbased'],
                        progDesignId: progDesignId.value,
                        rule: dataSource.value[k]['rule'],
                        valueOfExtend: dataSource.value[k]['fieldNameEn'],
                    };
                    queryParams.value.push(obj);
                }
            }
            const option = {
                name: 'docParaSetupFileNamingRuleVos',
                rawData: queryParams.value,
            };
            store.commit('claim/updateSaveParamsData', option);
        };
        onMounted(() => {
            customOn(
                'saveDocFileNamingRuleVosParams',
                saveDocFileNamingRuleVosParams
            );
        });

        return {
            progDesignId,
            extendInfoData,
            extendListData,
            findAllData,
            columns,
            onDocInfo,
            onExtend,
            saveDocFileNamingRuleVosParams,
            dataSource,
            claimMethodData,
        };
    },
});
