
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import moment, { Moment } from 'moment';
import useProgramData from '@/hooks/claim/useProgramData';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
import { claimprogramProgramId } from '@/API/claim/claimParameter';
interface DataItem {
  key: string;
  id?: string;
  period: string;
  eligiblePeriod: any;
  submissionDate: any;
}

export default defineComponent({
  props: {
    periodMap: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    periodSelectList: {
      type: Array,
      required: true,
      default: () => []
    },
    programCode: {
      type: String,
      required: true,
      default: ''
    },
    defaultDateRange: {
      type: Number,
      required: true,
      default: 0
    }
  },
  setup(props) {
    const store = useStore();
    const claimMethodData = store.state.claim.claimMethodData;
    const { customOn } = useEventbus();
    const columns = [
      {
        title: 'Period',
        dataIndex: 'period',
        width: '140px',
        slots: { customRender: 'period' }
      },
      {
        title: 'Eligible Period',
        dataIndex: 'eligiblePeriod',
        width: '240px',
        slots: { customRender: 'eligiblePeriod' }
      },
      {
        title: 'Submission Date',
        dataIndex: 'submissionDate',
        width: '240px',
        slots: { customRender: 'submissionDate' }
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' }
      }
    ];
    const dataSource = ref<DataItem[]>([]);
    const dateFormat = 'YYYY-MM-DD';
    // programId ,offerType数据获取
    const { progDesignId, periodList } = useProgramData();
    // //* Eligible Period
    // const eligiblePeriodFrom = ref<Moment>();
    // const eligiblePeriodTo = ref<Moment>();
    // //* Submission Date
    // const submissionDateFrom = ref<Moment>();
    // const submissionDateTo = ref<Moment>();
    // const dateRange = (props as any).defaultDateRange;
    const isSaved = ref<any>(true);
    //判断两个时间大小，‘1’则第一个时间靠后，’0‘则两个时间相同，‘-1’则第二个时间靠后
    const getLargeTiem = (aTime: any, bTime: any) => {
      const aTimeNum = new Date(aTime).getTime();
      const bTimeNum = new Date(bTime).getTime();
      if (!aTime || !bTime) {
        return false;
      }
      if (aTimeNum - bTimeNum > 0) {
        return '1';
      } else if (aTimeNum === bTimeNum) {
        return '0';
      } else {
        return '-1';
      }
    };
    // 回显数据获取
    const showData = (params: any) => {
      if (params) {
        dataSource.value = [];
        for (let i = 0; i < params.length; i++) {
          const obj: any = {};
          obj['key'] = `${i}`;
          obj['period'] = params[i].eligiblePeriodId;
          obj['progDesignId'] = params[i].progDesignId;
          obj['programCode'] = params[i].programCode;
          obj['eligiblePeriodName'] = params[i].eligiblePeriodName;
          obj['eligiblePeriod'] = [
            params[i].eligiblePeriodFrom ? moment(params[i].eligiblePeriodFrom, dateFormat) : '',
            params[i].eligiblePeriodTo ? moment(params[i].eligiblePeriodTo, dateFormat) : ''
          ];
          obj['submissionDate'] = [
            params[i].submissionDateFrom ? moment(params[i].submissionDateFrom, dateFormat) : '',
            params[i].submissionDateTo ? moment(params[i].submissionDateTo, dateFormat) : ''
          ];
          if (params[i].submissionDateFrom === '') {
            obj['submissionDate'][0] = moment(params[i].eligiblePeriodFrom, dateFormat);
          }
          if (params[i].submissionDateTo === '') {
            obj['submissionDate'][1] = moment(params[i].eligiblePeriodTo, dateFormat).add(
              (props as any).defaultDateRange,
              'days'
            );
          }
          dataSource.value.push(obj);
        }
      }
    };
    const timeAreaLst = ref<any>('');

    const selectPeriod = (value: string, record: any) => {
      record.eligiblePeriodName = (props as any).periodMap[value];
    };

    const onDelete = (key: string) => {
      dataSource.value = dataSource.value.filter((item) => item.key !== key);
    };
    const count = computed(() => dataSource.value.length + 1);
    const handleAdd = () => {
      const newData = {
        key: `${count.value}`,
        period: '',
        eligiblePeriodName: '',
        eligiblePeriod: [],
        submissionDate: [],
        programCode: (props as any).programCode
      };
      dataSource.value.push(newData);
    };

    const saveParaSetupSubmissionDeadline = () => {
      store.commit('claim/updateSaveParamsData', {
        name: 'claimParaSetupSubmissionDeadlineVos',
        rawData: []
      });
      const dataSourceLength = dataSource.value.length;
      if (dataSourceLength === 0) {
        return;
      }
      const { eligiblePeriod, submissionDate } = dataSource.value.reduce(
        (maps: any, row: any) => {
          maps['eligiblePeriod'] = [...maps['eligiblePeriod'], ...row.eligiblePeriod];
          maps['submissionDate'] = [...maps['submissionDate'], ...row.submissionDate];
          return maps;
        },
        { eligiblePeriod: [], submissionDate: [] }
      );
      if (eligiblePeriod.length !== 2 * dataSourceLength || submissionDate.length !== 2 * dataSourceLength) {
        return;
      }

      const queryParams = ref<any[]>([]);
      dataSource.value.forEach((item) => {
        queryParams.value.push({
          eligiblePeriodFrom: item.eligiblePeriod[0]?.format(dateFormat),
          eligiblePeriodId: item.period,
          eligiblePeriodName: (item as any).eligiblePeriodName,
          eligiblePeriodTo: item.eligiblePeriod[1]?.format(dateFormat),
          progDesignId: progDesignId.value,
          submissionDateFrom: item.submissionDate[0]?.format(dateFormat),
          submissionDateTo: item.submissionDate[1]?.format(dateFormat),
          programCode: (item as any).programCode
        });
      });
      const option = {
        name: 'claimParaSetupSubmissionDeadlineVos',
        rawData: queryParams.value
      };
      store.commit('claim/updateSaveParamsData', option);
    };
    onMounted(() => {
      customOn('saveParaSetupSubmissionDeadline', saveParaSetupSubmissionDeadline);
      customOn('showClaimParaSetupSubmissionData', showData);
    });
    const handleChangeEP = (date: any, key: any, rowItem: any) => {
      if (date && date[0] && date[1]) {
        const timeAreaArr = ref<any>([]);
        timeAreaLst.value.length > 0 &&
          timeAreaLst.value.forEach((lstI: any, lstInd: any) => {
            if (lstI.ATTR.val === rowItem.period) {
              timeAreaArr.value.push({
                timeAreaStart: lstI.FROM.val ? lstI.FROM.val : '',
                timeAreaEnd: lstI.TO.val ? lstI.TO.val : ''
              });
            }
          });
        const startTime = date[0] ? date[0].format(dateFormat) : '';
        const endTime = date[1] ? date[1].format(dateFormat) : '';
        let iscover = false;
        let nowIndex = 0;
        let inArea = false;
        timeAreaArr.value.length > 0 &&
          timeAreaArr.value.forEach((taaItem: any, taaIndex: any) => {
            if (
              (getLargeTiem(startTime, taaItem.timeAreaStart) === '1' ||
                getLargeTiem(startTime, taaItem.timeAreaStart) === '0') &&
              (getLargeTiem(endTime, taaItem.timeAreaEnd) === '-1' ||
                getLargeTiem(endTime, taaItem.timeAreaEnd) === '0')
            ) {
              console.log(false);
              inArea = true;
            }
          });
        if (!inArea && timeAreaArr.value.length > 0) {
          iscover = true;
        }
        dataSource.value.forEach((item, index) => {
          if (item.key !== key) {
            const startItemTime = item.eligiblePeriod[0] ? item.eligiblePeriod[0].format(dateFormat) : '';
            const endItemTime = item.eligiblePeriod[1] ? item.eligiblePeriod[1].format(dateFormat) : '';
            if (item.eligiblePeriod[0] !== '') {
              if (getLargeTiem(startTime, startItemTime) === '1' && getLargeTiem(startTime, endItemTime) === '-1') {
                iscover = true;
              }
              if (getLargeTiem(endTime, startItemTime) === '1' && getLargeTiem(endTime, endItemTime) === '-1') {
                iscover = true;
              }
            }
          } else {
            nowIndex = index;
          }
        });
        if (iscover) {
          message.error('You cannot choose current date.');
          dataSource.value[nowIndex].eligiblePeriod = [];
        }
      }
    };
    const handleChangeSD = (date: any, key: any) => {
      if (date && date[0] && date[1]) {
        const startTime = date[0] ? date[0].format(dateFormat) : '';
        const endTime = date[1] ? date[1].format(dateFormat) : '';
        let iscover = false;
        let nowIndex = 0;
        dataSource.value.forEach((item, index) => {
          const startItemTime = item.submissionDate[0] ? item.submissionDate[0].format(dateFormat) : '';
          const endItemTime = item.submissionDate[1] ? item.submissionDate[1].format(dateFormat) : '';
          const startItemEPTime = item.eligiblePeriod[0] ? item.eligiblePeriod[0].format(dateFormat) : '';
          const endItemEPTime = item.eligiblePeriod[1] ? item.eligiblePeriod[1].format(dateFormat) : '';
          if (item.key !== key) {
            if (item.submissionDate[0] !== '') {
              if (getLargeTiem(startTime, startItemTime) === '0' && getLargeTiem(endTime, endItemTime) === '0') {
                iscover = true;
              }
            }
          } else {
            nowIndex = index;
            if (item.eligiblePeriod[0] !== '' && item.submissionDate[0] !== '') {
              if (
                getLargeTiem(startItemTime, startItemEPTime) === '-1' ||
                getLargeTiem(endItemTime, endItemEPTime) === '-1'
              ) {
                iscover = true;
              }
            }
          }
        });
        if (iscover) {
          message.error('You cannot choose current submission date.');
          dataSource.value[nowIndex].submissionDate = [];
        }
      }
    };
    return {
      dateFormat,
      isSaved,
      getLargeTiem,
      selectPeriod,
      handleChangeEP,
      handleChangeSD,
      progDesignId,
      periodList,
      columns,
      onDelete,
      handleAdd,
      dataSource,
      showData,
      claimMethodData
    };
  }
});
