import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4f5b792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-table-wrap table-wrap" }
const _hoisted_2 = { class: "editable-cell" }
const _hoisted_3 = { class: "editable-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      "data-source": _ctx.dataSource,
      columns: _ctx.columns,
      pagination: false,
      rowClassName: 
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
            
    }, {
      docNameCn: _withCtx(({ index }) => [
        _createVNode(_component_a_input, {
          style: {"width":"180px"},
          value: _ctx.dataSource[index].docNameCn,
          "onUpdate:value": ($event: any) => ((_ctx.dataSource[index].docNameCn) = $event),
          disabled: ""
        }, null, 8, ["value", "onUpdate:value"])
      ]),
      docNameEn: _withCtx(({index}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_input, {
            value: _ctx.dataSource[index].docNameEn,
            "onUpdate:value": ($event: any) => ((_ctx.dataSource[index].docNameEn) = $event),
            disabled: ""
          }, null, 8, ["value", "onUpdate:value"])
        ])
      ]),
      fileType: _withCtx(({index}) => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_input, {
            value: _ctx.dataSource[index].docType,
            "onUpdate:value": ($event: any) => ((_ctx.dataSource[index].docType) = $event),
            disabled: ""
          }, null, 8, ["value", "onUpdate:value"])
        ])
      ]),
      fieldName: _withCtx(({ record , index}) => [
        _createVNode(_component_a_select, {
          value: record.fieldNameEn,
          "onUpdate:value": ($event: any) => ((record.fieldNameEn) = $event),
          onChange: (value) => _ctx.onExtend(value, record, index)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extendListData, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                value: item.id,
                key: item.id,
                title: item.fieldNameEn
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.fieldNameEn), 1)
                ]),
                _: 2
              }, 1032, ["value", "title"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["value", "onUpdate:value", "onChange"])
      ]),
      _: 1
    }, 8, ["data-source", "columns", "rowClassName"])
  ]))
}